import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "h-96 mt-6 w-full border shadow bg-white space-y-6 flex items-center justify-center rounded-lg flex-col"
}
const _hoisted_2 = { class: "text-lg text-center text-gray-900 font-semibold leading-tight" }
const _hoisted_3 = { class: "text-base font-medium" }
const _hoisted_4 = { class: "inline-flex items-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BaseIcon = _resolveComponent("BaseIcon")!
  const _component_BaseButton = _resolveComponent("BaseButton")!
  const _component_DataTable = _resolveComponent("DataTable")!
  const _component_InstructionsModal = _resolveComponent("InstructionsModal")!
  const _component_VirtualDoctorLayout = _resolveComponent("VirtualDoctorLayout")!

  return (_openBlock(), _createBlock(_component_VirtualDoctorLayout, null, {
    default: _withCtx(() => [
      (_ctx.error)
        ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
            _createVNode(_component_BaseIcon, {
              name: "cloud-error",
              height: "64px",
              stroke: false
            }),
            _createElementVNode("span", _hoisted_2, _toDisplayString(_ctx.$t('custom.menicon.virtual-doctor.instructions-error')), 1),
            _createVNode(_component_BaseButton, {
              "left-icon": "reload",
              color: "ghost",
              onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.getInstructions()))
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.$t('platform.error.try-again')), 1)
              ]),
              _: 1
            })
          ]))
        : (_openBlock(), _createBlock(_component_DataTable, {
            key: 1,
            "show-table-header": false,
            loading: _ctx.loading,
            columns: _ctx.columns,
            rows: _ctx.instructions,
            sort: "",
            class: "w-full mt-6"
          }, {
            "cell.title": _withCtx(({ value }) => [
              _createElementVNode("span", _hoisted_3, _toDisplayString(value), 1)
            ]),
            "cell.actions": _withCtx(({ row }) => [
              _createElementVNode("div", _hoisted_4, [
                _createVNode(_component_BaseButton, {
                  color: "ghost",
                  loading: _ctx.instructionsLoading === row.id,
                  disabled: _ctx.instructionsLoading && _ctx.instructionsLoading !== row.id,
                  onClick: ($event: any) => (_ctx.showInstructions(row.id))
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.$t('platform.common.view')), 1)
                  ]),
                  _: 2
                }, 1032, ["loading", "disabled", "onClick"])
              ])
            ]),
            _: 1
          }, 8, ["loading", "columns", "rows"])),
      (_ctx.showInstructionsModal)
        ? (_openBlock(), _createBlock(_component_InstructionsModal, {
            key: 2,
            route: _ctx.route,
            instructions: _ctx.selectedInstructions,
            onClose: _ctx.closeInstructions
          }, null, 8, ["route", "instructions", "onClose"]))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }))
}