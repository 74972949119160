
import { Vue, Options } from 'vue-class-component';
import VirtualDoctorLayout from '@/custom/menicon/layouts/VirtualDoctorLayout.vue';
import { BaseButton, DataTable } from '@/lib/components';
import { VirtualDoctorInstruction } from '@/models';
import { MeniconInstructionsService } from '@/services/api';
import BaseIcon from '@/lib/components/Icon/BaseIcon.vue';
import InstructionsModal from '@/custom/menicon/components/InstructionsModal.vue';
import { useSessionStore } from '@/stores/session.store';
import { useNotificationStore } from '@/stores/notification.store';

@Options({
  components: {
    InstructionsModal,
    BaseIcon,
    BaseButton,
    VirtualDoctorLayout,
    DataTable
  }
})
export default class VirtualDoctorInstructions extends Vue {
  loading = true;
  error = false;
  showInstructionsModal = false;

  instructions: VirtualDoctorInstruction[] = [];
  instructionsLoading: string | null = null;
  selectedInstructions: VirtualDoctorInstruction | null = null;
  service = new MeniconInstructionsService();
  sessionStore = useSessionStore();
  notificationStore = useNotificationStore();

  get columns() {
    return [
      { name: 'title', label: this.$t('platform.common.title') },
      { name: 'actions', label: '', align: 'right' }
    ];
  }

  get route() {
    return this.service.getEndpointUrl();
  }

  get organisationId() {
    return this.sessionStore.currentOrganisationId;
  }

  async created() {
    await this.getInstructions();
  }

  async getInstructions() {
    try {
      this.error = false;
      this.loading = true;
      this.instructions = (
        await this.service.index({
          params: {
            organisation_id: this.organisationId
          }
        })
      ).data;
    } catch (e) {
      this.error = true;
    } finally {
      this.loading = false;
    }
  }

  async fetchInstructions(id: string) {
    try {
      this.instructionsLoading = id;
      this.selectedInstructions = await this.service.fetch(id);
    } catch (e) {
      await this.notificationStore.addErrorNotification({
        title: this.$t('custom.menicon.virtual-doctor.fetch-instructions-error')
      });
    } finally {
      this.instructionsLoading = null;
    }
  }

  async showInstructions(id: string) {
    if (!this.selectedInstructions || this.selectedInstructions.id !== id) {
      await this.fetchInstructions(id);
    }
    this.showInstructionsModal = true;
  }

  closeInstructions() {
    this.showInstructionsModal = false;
  }
}
